// ----------------------------------------------------------------------

function path(root, sublink) {
	return `${root}${sublink}`;
}

export const ROOTS = {
	activity: '/activity',
	admin: '/admin',
	app: '/app',
	auth: '/auth',
	batch: '/batch',
	dashboard: '/',
	client: '/clients',
	report: '/report',
	distributor: '/distributors',
	farm: '/farms',
	farmer: '/farmers',
	product: '/product',
	consumer: '/consumer',
	members: '/members',
	productActivity: '/product-activity',
};

export const PATH_HOME = {
	dashboard: ROOTS.app,
};

export const PATH_PAGE = {
	auth: {
		root: ROOTS.auth,
		login: path(ROOTS.auth, '/login'),
		loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
		resetPassword: path(ROOTS.auth, '/reset-password'),
		verify: path(ROOTS.auth, '/verify'),
	},
	comingSoon: '/coming-soon',
};

export const PATH_APP = {
	root: ROOTS.dashboard,
	report: {
		list: path(ROOTS.report, ''),
	},
	dashboard: {
		list: path(ROOTS.client, ''),
		addClient: path(ROOTS.client, '/add-client'),
		details: path(ROOTS.client, '/:id'),
	},
	distributor: {
		list: path(ROOTS.distributor, ''),
	},
	farmer: {
		list: path(ROOTS.farmer, ''),
	},
	members: {
		details: path(ROOTS.members, '/:id'),
	},
	farm: {
		list: path(ROOTS.farm, ''),
		details: path(ROOTS.farm, '/:id'),
	},
	product: {
		list: path(ROOTS.product, ''),
		details: path(ROOTS.product, '/:id'),
	},
	activities: {},
	productActivity: {
		list: path(ROOTS.productActivity, ''),
		details: path(ROOTS.productActivity, '/:id'),
		floradetails: path(ROOTS.productActivity, '/flora/:id'),
		faunadetails: path(ROOTS.productActivity, '/fauna/:id'),
		othersdetails: path(ROOTS.productActivity, '/others/:id'),
		assign: path(ROOTS.productActivity, '/assign/"id'),
	},
	activity: {
		list: path(ROOTS.activity, ''),
		flora: path(ROOTS.activity, '/flora'),
		fauna: path(ROOTS.activity, '/fauna'),
	},
	batch: {
		list: path(ROOTS.batch, ''),
		details: path(ROOTS.batch, '/:id'),
		preview: path(ROOTS.batch, '/preview/:id'),
		consumerPreview: path(ROOTS.batch, 'consumer/preview/:id'),
	},
	consumerPreview: path(ROOTS.consumer, '/:id'),
	admin: {
		users: {
			list: path(ROOTS.admin, '/users'),
			details: path(ROOTS.admin, `/users/:id`),
		},
	},
};
